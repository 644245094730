import React from "react";
import { graphql, Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";

import richlinksStyle from "../css/richlinks.module.css";

const ContactPage = ({ data }) => {
  const { headline, body } = data.contactPage.data;
  return (
    <Layout>
      <div className="flex flex-col max-w-6xl mx-auto items-center flex-1 justify-center">
        <h1 className="text-6xl font-serif">{headline.text}</h1>
        <div
          className={richlinksStyle.rt}
          dangerouslySetInnerHTML={{ __html: body.html }}
        />
      </div>
    </Layout>
  );
};

export const query = graphql`
  {
    contactPage: prismicContactPage {
      data {
        headline {
          text
        }
        body {
          html
        }
      }
    }
  }
`;

export default ContactPage;
